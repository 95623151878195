import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Card, Row, Col } from 'react-bootstrap';
import ButtonGroup from './ButtonGroup';
import './Home.css';

const Home = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://pay.kraca.ru/api', 
          { method: 'info' }, 
          { headers: { 'Content-Type': 'application/json' } }
        );
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container className="mt-4 d-flex justify-content-center">
      <Card className="info-card">
        <Card.Body>
          <Card.Title className="text-center mb-4">Информация от API</Card.Title>
          {data ? (
           <div className="api-info">
              <Row>
                <Col>
                  <p className="info-label">Кол-во пользователей:</p>
                  <p className="info-value">{data.totalUsers}</p>
                  <p className="info-label">Зарегистрировались:</p>
                  <p className="info-value">Сегодня: {data.registered.day}</p> {/* Добавляем количество регистраций за день */}
                  <p className="info-value">Неделя: {data.registered.week}</p> {/* Добавляем количество регистраций за неделю */}
                  <p className="info-value">Месяц: {data.registered.month}</p> {/* Добавляем количество регистраций за месяц */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="info-label">Пользователей с подпиской:</p>
                  <ul className="subscription-list">
                    <li><span className="subscription-type">Start:</span> <span className="subscription-value">{data.subscription.Start}</span></li>
                    <li><span className="subscription-type">Master:</span> <span className="subscription-value">{data.subscription.Master}</span></li>
                    <li><span className="subscription-type">Year:</span> <span className="subscription-value">{data.subscription.Year}</span></li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="info-label">Активность:</p>
                  <ul className="subscription-list">
                    <li><span className="subscription-type">За сутки:</span> <span className="subscription-value">{data.active.day}</span></li>
                    <li><span className="subscription-type">За неделю:</span> <span className="subscription-value">{data.active.week}</span></li>
                    <li><span className="subscription-type">За месяц:</span> <span className="subscription-value">{data.active.month}</span></li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="info-label">Успешные платежи:</p>
                  <ul className="subscription-list">
                    <li><span className="subscription-type">За сутки - Старт:</span> <span className="subscription-value">{data.archive.start.day}</span></li>
                    <li><span className="subscription-type">За неделю - Старт:</span> <span className="subscription-value">{data.archive.start.week}</span></li>
                    <li><span className="subscription-type">За месяц - Старт:</span> <span className="subscription-value">{data.archive.start.month}</span></li>
                    <li><span className="subscription-type">За сутки - Мастер:</span> <span className="subscription-value">{data.archive.master.day}</span></li>
                    <li><span className="subscription-type">За неделю - Мастер:</span> <span className="subscription-value">{data.archive.master.week}</span></li>
                    <li><span className="subscription-type">За месяц - Мастер:</span> <span className="subscription-value">{data.archive.master.month}</span></li>
                    <li><span className="subscription-type">За сутки - Годовой:</span> <span className="subscription-value">{data.archive.year.day}</span></li>
                    <li><span className="subscription-type">За неделю - Годовой:</span> <span className="subscription-value">{data.archive.year.week}</span></li>
                    <li><span className="subscription-type">За месяц - Годовой:</span> <span className="subscription-value">{data.archive.year.month}</span></li>
                  </ul>
                </Col>
              </Row>
            </div>
          ) : (
            <p>Загрузка...</p>
          )}
          <ButtonGroup />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Home;
